<template>
  <BaseLink
    v-if="item.link"
    :link="item.link"
    class="item"
    @click="handleClick"
  >
    <span class="content">
      <span class="additional-content">
        <span v-html="nbsp(item.label || item.link.label)" />
      </span>
    </span>
  </BaseLink>
</template>

<script setup lang="ts">
import type { PublicSecondLevelMenuItem } from '@/service/__generated-api'

const props = defineProps<{
  item: PublicSecondLevelMenuItem
}>()

const emit = defineEmits(['click'])

const preventSmoothScroll = () => {
  if (props.item.link?.subsection)
    document.documentElement.style.scrollBehavior = 'auto'
  setTimeout(() => {
    document.documentElement.style.scrollBehavior = 'smooth'
  }, 0)
}

const handleClick = () => {
  emit('click')
  preventSmoothScroll()
}
</script>

<style lang="scss" scoped>
.item {
  display: inline-flex;
  width: 100%;
  text-align: start;
  transition: color $transition;

  &:hover {
    font-weight: 500;
  }
}

.additional-content {
  display: flex;
  flex-direction: column;
  gap: rem(4px);
  margin-top: rem(2px);
}

.content {
  display: inline-flex;
}

.item .content .icon {
  @include size(rem(20px));
  min-width: rem(20px);
  min-height: rem(20px);
  margin-right: rem(16px);
}
</style>
