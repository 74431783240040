<template>
  <nav
    v-if="links.length"
    class="grid breadcrumbs"
    :class="`breadcrumbs--${color}`"
  >
    <ul class="list">
      <li class="item">
        <NuxtLink :to="localePath({ name: 'index' })" class="link">
          {{ t('homepage') }}
        </NuxtLink>
      </li>
      <li v-for="(link, index) in links" :key="index" class="item">
        <NuxtLink v-if="link.to" :to="localePath(link.to)" class="link">
          {{ link.title }}
        </NuxtLink>
      </li>
    </ul>
  </nav>
</template>

<script lang="ts" setup>
const localePath = useLocalePath()
const { t } = useI18n()

const { color, links } = useBreadcrumbs()
</script>

<style lang="scss" scoped>
.breadcrumbs {
  position: absolute;
  z-index: z(breadcrumbs);
  width: 100%;
  margin-top: rem(16px);

  &--beige-mobile-brown-desktop {
    color: $beige;

    @include media-up(lg) {
      color: $dark-brown;
    }
  }

  &--beige {
    color: $beige;
  }

  &--brown {
    color: $dark-brown;
  }
}

.list {
  @include font-size(
    (
      xs: 11px,
      md: 12px,
      lg: 13px
    )
  );
  @include media-column(
    (
      xs: 24
    )
  );
  display: flex;
  font-weight: 400;
}

.item {
  display: flex;

  &:not(:last-child) {
    &::after {
      margin: 0 rem(7px);
      content: '/';
    }
  }
}

.link {
  .item:last-child & {
    max-width: 50vw;
    overflow: hidden;
    text-decoration: underline;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>
