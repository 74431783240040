<template>
  <div class="header">
    <NavigationBar
      v-if="menu"
      :menu="menu"
      :active-menu="isActive"
      @open="openMenu"
      @close="closeMenu"
    />
    <ClientOnly>
      <NavigationSidebar
        v-if="!isDesktop"
        :menu="menu"
        :active="isActive"
        @close="closeMenu"
      />
    </ClientOnly>
  </div>
</template>

<script setup lang="ts">
const { data: menu } = await useAsyncData('websiteMenu', () =>
  useApi().publicMenuMainRetrieve()
)

const isDesktop = useIsDesktop()
const isActive = ref(false)

const openMenu = () => {
  isActive.value = true
}
const closeMenu = () => {
  isActive.value = false
}
</script>

<style lang="scss" scoped>
.header {
  position: relative;
  z-index: z(header-navigation);
}
</style>
