<template>
  <Component
    :is="tag"
    class="nav-item"
    :class="{ 'nav-item--with-dropdown': item.items?.length }"
    @mouseenter="handleHoverDropdown"
    @mouseleave="handleHoverEndDropdown"
  >
    <BaseLink v-if="item.link" :link="item.link" class="lnk">
      {{ item.label }}
    </BaseLink>
    <template v-else>
      <button class="lnk">
        {{ item.label }}
        <ChevronIcon class="chevron" />
      </button>
      <NavigationDropdown
        v-if="item.items?.length"
        :item="item"
        class="dropdown"
      >
        <template #default="{ subItem, elClass }">
          <NavigationSubItem
            :class="elClass"
            :item="subItem"
            class="sub-item"
            @click="handleLinkClick"
          />
        </template>

        <template #footer="{ footerItem }">
          <NavigationFooterSubItem
            :item="footerItem"
            with-background
            @click="handleLinkClick"
          />
        </template>
      </NavigationDropdown>
    </template>
  </Component>
</template>

<script setup lang="ts">
import ChevronIcon from '@/assets/icons/chevron-down.svg?component'
import type { PublicMenuItem } from '@/service/__generated-api'

const props = withDefaults(
  defineProps<{ tag?: 'li' | 'div'; item: PublicMenuItem }>(),
  {
    tag: 'li'
  }
)

const emit = defineEmits(['show-dropdown', 'hide-dropdown'])

const handleHoverDropdown = () => {
  if (props.item.link || !props.item.items?.length) return
  document.body.classList.add('dropdown-active')
  emit('show-dropdown')
}

const handleHoverEndDropdown = () => {
  document.body.classList.remove('dropdown-active')
  emit('hide-dropdown')
}

const handleLinkClick = () => {
  if (document.activeElement instanceof HTMLElement)
    document.activeElement.blur()
}
</script>

<style lang="scss">
.route-change .nav-item--with-dropdown,
.route-change .nav-item--with-dropdown:hover .dropdown {
  pointer-events: none !important;
}
</style>

<style lang="scss" scoped>
.lnk {
  @include center-content;
  @include font-size(
    (
      xs: 15px,
      xl: 19px
    ),
    1.2
  );
  display: flex;
  gap: rem(7px);
  align-items: center;
  height: 100%;
  cursor: pointer;
  transition: color $transition;

  &:hover {
    font-weight: 500;
  }
}

.chevron {
  width: rem(8px);
  transition: transform $transition;
}

.dropdown {
  pointer-events: none;
  visibility: hidden;
  opacity: 0;
  transition: all $transition;
}

.nav-item {
  position: relative;
  height: 100%;
  padding-inline: rem(13.5px);

  &::after {
    position: absolute;
    top: 0;
    display: block;
    width: rem(97px);
    height: rem(55px);
    content: '';
    background-image: url('@/assets/icons/drip.svg');
    transition: transform $transition-slow;
    transform: translateY(rem(-55px));

    .contrast-mode & {
      @include recolor(black, $black-filter);
    }
  }

  &--with-dropdown:hover {
    .dropdown {
      pointer-events: all;
      visibility: visible;
      opacity: 1;
    }

    .lnk .chevron {
      transform: rotate(180deg);
    }

    &::after {
      transform: translateY(0);
    }
  }

  &:first-child {
    padding-left: 0;
  }
}

.sub-item {
  width: rem(153px);
}
</style>
