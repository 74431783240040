<template>
  <NuxtLink :to="localePath({ name: 'index' })" class="logo">
    <BaseImage
      v-if="logo"
      :image="logo"
      v-bind="
        getImageSizes({
          xs: { w: 140, h: 39 },
          lg: { w: 214, h: 60 }
        })
      "
      fit="fit"
      :class="`logo logo--${type} logo--contrast-${contrastModeColor}`"
      :lazyload="false"
    />
  </NuxtLink>
</template>

<script lang="ts" setup>
import { useContrastMode } from '@/state/a11yState'
import { useWebsiteSettings } from '@/state/websiteSettings'

const props = withDefaults(
  defineProps<{
    type?: 'primary' | 'secondary'
    contrastModeColor?: 'black' | 'yellow'
  }>(),
  {
    type: 'primary',
    contrastModeColor: 'black'
  }
)

const settings = await useWebsiteSettings()
const localePath = useLocalePath()
const contrastMode = useContrastMode()

const logo = computed(() =>
  props.type === 'primary' || contrastMode.value
    ? settings?.value?.logotype
    : settings?.value?.secondary_logotype
)
</script>

<style lang="scss" scoped>
.logo {
  @include focus-visible;

  display: block;
  cursor: pointer;
  transition: opacity $transition;

  :deep(img) {
    width: 140px;
    height: auto;

    @include media-up(lg) {
      width: 214px;
    }

    @include media-up(xl) {
      width: 224px;
    }
  }

  &:hover {
    opacity: 0.75;
  }

  .contrast-mode &--contrast-black {
    @include recolor(black, $black-filter);
  }

  .contrast-mode &--contrast-yellow {
    @include recolor($contrast-yellow-default, $contrast-yellow-filter);
  }
}
</style>
