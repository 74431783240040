<template>
  <div class="font-switcher">
    <button
      v-for="[size, label] in sizes"
      :key="size"
      :aria-label="label"
      :class="[`letter--${size}`, { 'letter--active': fontSize === size }]"
      class="letter"
      @click="fontSize = size"
    >
      A
    </button>
  </div>
</template>

<script lang="ts" setup>
import { useFontSize, type FontSize } from '@/state/a11yState'

const { t } = useI18n()
const fontSize = useFontSize()

const sizes: [FontSize, string][] = [
  ['small', t('small-text-size')],
  ['medium', t('medium-text-size')],
  ['large', t('large-text-size')]
]
</script>

<style lang="scss" scoped>
.font-switcher {
  white-space: nowrap;
}

.letter {
  @include focus-visible;

  font-weight: 600;
  color: $beige-gray;
  text-align: center;
  transition: color $transition;

  &--small {
    padding-right: rem(4px);
    font-size: 12px;

    @include media-up(md) {
      font-size: 14px;
    }

    @include media-up(lg) {
      font-size: 16px;
    }
  }

  &--medium {
    padding-inline: rem(4px);
    font-size: 16px;

    @include media-up(md) {
      font-size: 18px;
    }

    @include media-up(lg) {
      font-size: 20px;
    }
  }

  &--large {
    padding-left: rem(4px);
    font-size: 20px;

    @include media-up(md) {
      font-size: 22px;
    }

    @include media-up(lg) {
      font-size: 24px;
    }
  }

  .dark-mode & {
    color: $faded-brown;
  }

  &--active {
    color: $dark-brown;

    .dark-mode & {
      color: $beige;
    }
  }
}
</style>
