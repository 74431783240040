<template>
  <AnimatedRive class="rive" src="/rive/zebra.riv" />
</template>

<script lang="ts" setup></script>

<style lang="scss" scoped>
.rive {
  aspect-ratio: 1/1;
}
</style>
