<template>
  <button
    :aria-label="
      contrastMode ? t('turn-off-high-contrast') : t('turn-on-high-contrast')
    "
    class="contrast-switcher"
    @click="toggleContrastMode"
  >
    <ContrastIcon class="icon" />
  </button>
</template>

<script lang="ts" setup>
import ContrastIcon from '@/assets/icons/contrast.svg?component'
import { useContrastMode } from '@/state/a11yState'

const { t } = useI18n()
const contrastMode = useContrastMode()

function toggleContrastMode() {
  contrastMode.value = !contrastMode.value
}
</script>

<style lang="scss" scoped>
.contrast-switcher {
  @include focus-visible;
  display: flex;

  .icon {
    --background-color: #{$beige};
    color: $dark-brown;
    filter: drop-shadow($base-shadow);

    @include media-up(md) {
      @include size(24px);
    }

    @include media-up(lg) {
      @include size(22px);
    }

    .dark-mode & {
      --background-color: #{$dark-brown};
      color: $beige;
    }
  }
}
</style>
