import UAParser from 'ua-parser-js'

export function useIsSafari() {
  const headers = useRequestHeaders()

  const detectSafari = (ua?: string) => {
    const parser = new UAParser(ua)
    const parserResults = parser.getResult()

    return !!(
      parserResults.os.name === 'iOS' ||
      parserResults.browser.name?.includes('Safari')
    )
  }

  const isSafari = useState('isSafari', () =>
    process.server ? detectSafari(headers['user-agent']) : detectSafari()
  )

  useHead({
    htmlAttrs: () => ({
      class: isSafari.value ? 'is-safari' : ''
    })
  })
}
