<template>
  <footer
    class="footer"
    :class="{ 'footer--without-newsletter': !SHOW_NEWSLETTER }"
  >
    <div class="line" />

    <div class="main-content">
      <div v-if="SHOW_NEWSLETTER" class="column">
        <h2 class="title">Bądź na bieżąco?</h2>
        <p class="description">Zapisz się na newsletter?</p>
        <Transition name="fade" mode="out-in">
          <NewsletterForm
            v-if="!formSent"
            :settings="{
              first_agreement: {
                id: 'string',
                text: 'first_agreement',
                is_required: true
              },
              second_agreement: {
                id: 'string',
                text: 'second_agreement',
                is_required: false
              },
              third_agreement: {
                id: 'string',
                text: 'third_agreement',
                is_required: true
              },
              id: ''
            }"
            @success="formSent = true"
          />
          <p v-else class="description">Wysłano?</p>
        </Transition>
      </div>

      <div v-if="SHOW_NEWSLETTER" class="line main-content-line lg-down" />

      <div class="column links-column">
        <nav class="main-links">
          <ul
            v-for="(items, index) in chunkedItems"
            :key="index"
            class="main-links-list"
          >
            <li v-for="(item, index2) in items" :key="index2">
              <BaseLink :link="item.link" class="link main-link">
                <span v-html="nbsp(item.label)" />
              </BaseLink>
            </li>
          </ul>
        </nav>

        <nav class="secondary-links">
          <ul class="secondary-links-list">
            <li v-for="(item, index) in footer?.secondary_items" :key="index">
              <BaseLink :link="item.link" class="link secondary-link">
                <span v-html="nbsp(item.label)" />
              </BaseLink>
            </li>
          </ul>

          <Socials type="brown" class="socials" />
        </nav>
      </div>
    </div>

    <div class="line" />

    <div class="logos">
      <Logo class="logo column" contrast-mode-color="yellow" />

      <div class="creators column">
        <span class="creators-text">www made by:</span>
        <ul class="creators-list">
          <li><PanowieIcon class="creator-icon" /></li>
          <li><TwinIcon class="creator-icon" /></li>
        </ul>
      </div>
    </div>

    <AnimatedZebra class="zebra" />
  </footer>
</template>

<script lang="ts" setup>
import { chunk } from 'lodash-es'

import PanowieIcon from '@/assets/icons/panowie.svg?component'
import TwinIcon from '@/assets/icons/twin.svg?component'

const SHOW_NEWSLETTER = false

const { data: footer } = await useAsyncData('websiteFooter', () =>
  useApi().publicMenuFooterRetrieve({})
)

const formSent = ref(false)

const chunkedItems = chunk(
  footer.value?.items,
  Math.ceil((footer.value?.items.length || 0) / 2)
)
</script>

<style lang="scss" scoped>
@mixin footer-grid {
  @include media-up(md) {
    display: grid;
    grid-template-columns: rem(230px) 1fr;
    gap: rem(20px);
  }

  @include media-up(lg) {
    grid-template-columns: 1fr 1fr;
    gap: rem(117px);
  }

  @include media-up(xl) {
    gap: rem(165px);
  }
}

$padding: var(--padding);

.footer {
  --padding: #{rem(25px)};
  position: relative;
  overflow: hidden;
  color: $dark-brown;
  background: $beige;

  @include media-up(md) {
    --padding: #{rem(30px)};
  }

  @include media-up(lg) {
    --padding: #{rem(78px)};
  }

  @include media-up(xl) {
    --padding: #{rem(110px)};
  }

  .contrast-mode & {
    color: $contrast-yellow;
    background-color: $contrast-black;
  }

  &--without-newsletter {
    margin-top: rem(60px);

    @include media-up(md) {
      margin-top: 0;
    }
  }
}

.line {
  height: 1px;
  margin: 0 $padding;
  background: $dark-brown-20;

  @include media-up(lg) {
    margin: 0 rem(53px);
  }
}

.main-content-line {
  margin: 0;
}

.main-content {
  padding-block: rem(68px) rem(36px);
  padding-inline: $padding;

  @include media-up(lg) {
    @include footer-grid;
  }

  @include media-up(xl) {
    padding-block: rem(197px) rem(78px);
  }
}

.title {
  @include font-size(
    (
      xs: 36px,
      md: 54px,
      lg: 64px,
      xl: 90px
    )
  );
  margin-bottom: rem(31px);
  font-weight: 600;
  letter-spacing: -0.025em;

  @include media-up(md) {
    margin-bottom: rem(25px);
  }
}

.description {
  @include font-size(
    (
      xs: 21px,
      md: 27px,
      lg: 32px,
      xl: 44px
    )
  );
  margin-bottom: rem(52px);
  font-weight: 400;
  letter-spacing: -0.025em;

  @include media-up(lg) {
    margin-bottom: rem(68px);
  }

  @include media-up(xl) {
    margin-bottom: rem(96px);
  }
}

// Newsletter below
.main-links {
  @include footer-grid;
  @include font-size(
    (
      xs: 16px,
      md: 20px,
      lg: 22px,
      xl: 30px
    )
  );
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: rem(20px);
  margin: rem(51px) 0;
  font-weight: 600;

  @include media-up(lg) {
    margin-block: rem(20px) rem(111px);
  }

  @include media-up(xl) {
    margin-block: rem(14px) rem(156px);
  }

  .footer--without-newsletter & {
    @include media-up(lg) {
      flex-shrink: 0;
      margin-top: 0;
      margin-right: rem(140px);
    }
  }
}

.main-links-list {
  display: flex;
  flex-direction: column;
  gap: rem(27px);

  @include media-up(lg) {
    gap: rem(20px);
  }
}

.main-link {
  display: block;
}

.secondary-links {
  @include footer-grid;
  @include font-size(
    (
      xs: 13px,
      md: 16px,
      xl: 22px
    )
  );
  font-weight: 600;

  @include media-up(lg) {
    display: block;
  }

  .footer--without-newsletter & {
    @include media-up(lg) {
      flex-shrink: 0;
    }
  }
}

.secondary-links-list {
  display: flex;
  flex-direction: column;
  gap: rem(15px);
  max-width: 50%;

  @include media-up(md) {
    max-width: none;
  }
}

.secondary-link {
  display: block;
}

.link {
  transition: opacity $transition;

  &:hover {
    opacity: 0.75;
  }
}

.socials {
  margin-top: rem(31px);

  @include media-up(md) {
    margin-top: rem(12px);
  }

  @include media-up(lg) {
    margin-top: rem(42px);
  }

  @include media-up(xl) {
    margin-top: rem(60px);
  }
}

.logos {
  @include footer-grid;
  display: grid;
  grid-template-columns: 1fr auto;
  align-items: center;
  padding-block: rem(37px) rem(56px);
  padding-inline: $padding;

  @include media-up(xl) {
    padding-block: rem(52px);
  }
}

.logo {
  width: max-content;

  :deep(img) {
    @include media-up(lg) {
      width: rem(272px);
    }

    @include media-up(xl) {
      width: rem(382px);
    }
  }
}

.creators {
  @include media-up(md) {
    display: flex;
    gap: rem(30px);
  }
}

.creators-list {
  display: flex;
  gap: rem(21px);
  height: rem(12px);

  @include media-up(md) {
    gap: rem(26px);
    height: rem(15px);
  }

  @include media-up(lg) {
    gap: rem(35px);
  }

  @include media-up(md) {
    height: rem(20px);
  }
}

.creators-text {
  @include font-size(
    (
      xs: 8px,
      md: 10px,
      lg: 12px,
      xl: 16px
    )
  );
  display: block;
  margin-bottom: rem(10px);
  font-weight: 600;
  text-align: right;

  @include media-up(md) {
    margin-bottom: 0;
  }
}

.creator-icon {
  @include size(100%);
  display: block;
  color: $dark-brown;

  .contrast-mode & {
    color: $contrast-yellow;
  }
}

.zebra {
  position: absolute;
  right: rem(-150px);
  bottom: rem(65px);
  width: rem(340px);
  pointer-events: none;

  @include media-up(md) {
    right: rem(-220px);
    bottom: rem(-120px);
    width: rem(500px);
  }

  @include media-up(lg) {
    right: rem(-210px);
    bottom: rem(-145px);
    width: rem(670px);
  }

  @include media-up(xl) {
    right: rem(-310px);
    bottom: rem(-180px);
    width: rem(900px);
  }
}

.footer--without-newsletter .links-column {
  @include media-up(lg) {
    display: flex;
    flex-direction: row;
  }
}
</style>
