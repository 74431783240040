<template>
  <div class="sidebar-wrapper">
    <aside ref="sidebar" :class="{ 'sidebar--active': active }" class="sidebar">
      <nav class="nav">
        <StyledCollapse :items="menu?.items || []">
          <template #link="{ item, elClass }">
            <BaseLink
              v-if="item.link"
              :link="item.link"
              :class="elClass"
              @click="closeSidebar"
            >
              {{ item.label }}
            </BaseLink>
            <span v-else :class="elClass">
              {{ item.label }}
            </span>
          </template>

          <template #default="{ subItem, elClass, isListActive }">
            <NavigationSubItem
              :class="elClass"
              :item="subItem"
              :tabindex="isListActive ? undefined : -1"
              @click="closeSidebar"
            />
          </template>
        </StyledCollapse>
        <div class="footer">
          <NavigationA11yBar />
        </div>
      </nav>
    </aside>
  </div>
</template>

<script setup lang="ts">
import type { PublicMainMenu } from '@/service/__generated-api'

const emit = defineEmits(['close'])
const props = withDefaults(
  defineProps<{ menu: Partial<PublicMainMenu> | null; active?: boolean }>(),
  {
    active: false
  }
)
const sidebar = ref<HTMLElement>()
const { blockScroll, unblockScroll } = useModal(sidebar)

watch(
  () => props.active,
  () => (props.active ? blockScroll() : unblockScroll())
)

const closeSidebar = () => {
  unblockScroll()
  emit('close')
}
</script>

<style lang="scss" scoped>
.sidebar-wrapper {
  @include hide-up(lg);
}

.sidebar {
  @include scrollbar-mask($beige);
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-inline: rem(25px);

  padding-top: $nav-height;
  overflow-y: auto;

  color: $pure-white;
  background-color: $beige;
  transition: transform $dynamic-transition;

  transform: translateY(-100%);

  &--active {
    transform: none;
  }
}

.nav {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
}

.footer {
  padding-block: rem(30px);
  border-top: 1px solid $dark-brown-20;
}
</style>
