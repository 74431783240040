<template>
  <CollapseGroup id="menu" tag="ul" class="list alternative-focus-outline">
    <Component
      :is="withSublist(item) ? Collapse : 'li'"
      v-bind="withSublist(item) ? { tag: 'li', id: `menu_${i}` } : {}"
      v-for="(item, i) in items"
      :key="i"
      :class="{
        'list-item--with-sublist': withSublist(item),
        'list-item--last': i === items.length - 1
      }"
      class="list-item"
    >
      <CollapseTitle v-if="withSublist(item)" class="item-text collapse-title">
        <slot name="link" el-class="link" :item="item" />
        <ChevronIcon class="chevron" />
      </CollapseTitle>
      <div v-else class="item-text">
        <slot name="link" el-class="link" :item="item" />
      </div>

      <CollapseContent
        v-if="withSublist(item)"
        v-slot="{ isActive }"
        class="collapse"
      >
        <Component :is="forceSublist ? 'div' : 'ul'" class="sublist">
          <Component
            :is="forceSublist ? 'div' : 'li'"
            v-for="(subItem, index) in item.items"
            :key="index"
            class="sub-link-item"
            :class="{
              'sub-link-item--last': index === (item.items?.length || 1) - 1
            }"
          >
            <slot
              el-class="sub-link"
              :sub-item="subItem"
              :is-list-active="isActive"
            />
          </Component>
        </Component>
      </CollapseContent>
    </Component>
  </CollapseGroup>
</template>

<script setup lang="ts">
import ChevronIcon from '@/assets/icons/chevron-down.svg?component'
import type {
  PublicMenuItem,
  PublicSecondLevelMenuItem
} from '@/service/__generated-api'

const props = withDefaults(
  defineProps<{
    items: PublicMenuItem[]
    forceSublist?: boolean
  }>(),
  { forceSublist: false }
)

const Collapse = resolveComponent('Collapse')
const CollapseContent = resolveComponent('CollapseContent')

const withSublist = (item: PublicMenuItem | PublicSecondLevelMenuItem) =>
  ('items' in item && item.items?.length) || props.forceSublist
</script>

<style lang="scss" scoped>
.list {
  margin-top: rem(54px - 29px);
  color: $dark-brown;

  @include media-up(md) {
    margin-inline: rem(120px);
    margin-top: rem(66px);
  }
}

.list-item {
  @include font-size(
    (
      xs: 24px
    ),
    1.2
  );

  &:deep(.link),
  &:deep(.sub-link) {
    @include focus-visible;
  }
}

.item-text {
  padding-block: rem(29px);
}

.collapse-title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid $dark-brown-20;
}

.chevron {
  transition: transform $transition;
}

:deep(.input:checked) ~ .collapse-title .chevron {
  transform: rotate(-180deg);
}

.collapse {
  width: 100%;
}

.sublist {
  @include font-size(
    (
      xs: 17px
    ),
    1.2
  );
  display: grid;
  grid-template-columns: max-content max-content;
  grid-gap: rem(25px) rem(35px);
  padding-top: rem(30px);
  padding-bottom: rem(46px - 29px);
  font-weight: 500;
}
</style>
