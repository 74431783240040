<template>
  <li class="lg-up links-wrap">
    <ul class="links">
      <NavigationItem
        v-for="(item, i) in items"
        :key="i"
        :item="item"
        class="desktop-link"
        @show-dropdown="debouncedEmit('show-dropdown')"
        @hide-dropdown="debouncedEmit('hide-dropdown')"
      />
    </ul>
  </li>
</template>

<script lang="ts" setup>
import { useDebounceFn } from '@vueuse/core'

import type { PublicMenuItem } from '@/service/__generated-api'

const emit = defineEmits(['show-dropdown', 'hide-dropdown'])

// Avoid unneccessary hide-dropdown when switching items
const debouncedEmit = useDebounceFn(
  (event: Parameters<typeof emit>[0]) => emit(event),
  10
)

defineProps<{
  items?: PublicMenuItem[]
}>()
</script>

<style lang="scss" scoped>
.links,
.links-wrap {
  height: 100%;
}

.links {
  display: flex;
  align-items: center;
}

.footer-links {
  display: flex;
  gap: rem(20px);
  align-items: center;
}
</style>
