<template>
  <button
    class="hamburger-button"
    :class="{ 'hamburger-button--active': active }"
  >
    <span
      :aria-label="active ? t('menu.close') : t('menu.open')"
      class="hamburger"
    >
      <span v-for="i in 3" :key="i" class="bar" />
    </span>
  </button>
</template>

<script lang="ts" setup>
withDefaults(defineProps<{ active: boolean }>(), { active: false })

const { t } = useI18n()
</script>

<style lang="scss" scoped>
.hamburger-button {
  @include size(rem(32px));
  display: grid;
  place-content: center;
  background-color: $beige;
  border-radius: 16px;
  box-shadow: $base-shadow;

  &--active {
    background-color: $dark-brown;
  }
}

.hamburger {
  @include focus-visible;
  @include press-area;
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: space-between;

  width: rem(12px);
  height: rem(10px);
}

.bar {
  display: block;
  width: rem(12px);
  height: rem(2px);
  background-color: $dark-brown;
  border-radius: rem(3px);
  transition: all $transition;

  &:nth-child(1) {
    transform-origin: 0% 50%;
  }

  &:nth-child(3) {
    transform-origin: 100% 50%;
  }

  .hamburger-button--active & {
    background-color: $beige;
  }

  .hamburger-button--active &:nth-child(1) {
    transform: translateX(11%) rotate(45deg);
  }

  .hamburger-button--active &:nth-child(2) {
    transform: rotate(-45deg);
  }

  .hamburger-button--active &:nth-child(3) {
    opacity: 0;
  }
}
</style>
