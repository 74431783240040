<template>
  <header
    :class="{
      'active-menu': isActive,
      'active-dropdown': isDropdownActive
    }"
    class="navigation-bar"
  >
    <nav id="nav" class="nav">
      <div class="background" />
      <ul class="nav-elements">
        <ClientOnly>
          <NavigationDesktopLinks
            v-if="isDesktop"
            :items="menu?.items"
            @show-dropdown="isDropdownActive = true"
            @hide-dropdown="isDropdownActive = false"
          />
          <template #fallback>
            <NavigationDesktopLinks :items="menu?.items" />
          </template>
        </ClientOnly>

        <li>
          <Logo
            :type="isActive ? 'primary' : 'secondary'"
            :contrast-mode-color="isActive ? 'black' : 'yellow'"
          />
        </li>

        <li class="right-side">
          <NavigationA11yBar :dark-mode="!isActive" class="lg-up" />
          <BaseButton
            class="cta-button"
            :text="t('buy-ticket')"
            :base-link="menu.ticket_link"
          />
          <NavigationHamburger
            :active="activeMenu"
            class="lg-down"
            @click="handleClick"
          />
        </li>
      </ul>
    </nav>
  </header>
</template>

<script setup lang="ts">
import { useWindowScroll } from '@vueuse/core'

import type { PublicMainMenu } from '@/service/__generated-api'

const props = withDefaults(
  defineProps<{
    menu: PublicMainMenu
    activeMenu: boolean
  }>(),
  {
    activeMenu: false
  }
)

const { t } = useI18n()

const emit = defineEmits(['open', 'close'])
const { y: windowScroll } = useWindowScroll()
const isDesktop = useIsDesktop()
const isDropdownActive = ref(false)

const handleClick = () => {
  emit(props.activeMenu ? 'close' : 'open')
}

const color = useHeaderColor()

const isScrolled = computed(() => windowScroll.value > 0)
const isActive = computed(
  () =>
    props.activeMenu ||
    isDropdownActive.value ||
    isScrolled.value ||
    color.value === 'beige'
)

useHead({
  bodyAttrs: {
    class: computed(() => (isScrolled.value ? 'window-scrolled' : ''))
  }
})
</script>

<style lang="scss" scoped>
$inline-padding: var(--menu-inline-padding);

.navigation-bar {
  position: relative;
  min-height: $nav-height;
  color: $beige;
  transition: min-height $transition;
}

.active-menu {
  color: $dark-brown;
}

.nav {
  --menu-inline-padding: #{rem(25px)};
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1;
  min-height: inherit;
  padding-inline: $inline-padding;

  @include media-up(md) {
    --menu-inline-padding: #{rem(30px)};
  }

  @include media-up(lg) {
    --menu-inline-padding: #{rem(53px)};
  }

  &::after {
    @include fill-space;
    z-index: -1;
    content: '';
    background-color: $dark-brown;
    transition: background-color $transition;

    .active-menu & {
      background-color: $beige;
    }
  }

  &::before {
    position: fixed;
    right: 0;
    left: 0;
    display: block;
    height: 0;
    content: '';
    background-color: $dark-brown;
    transition: height $transition;

    .dropdown-active & {
      height: rem(6px);
    }
  }

  & > * {
    align-self: center;
    transition: filter $transition;
  }
}

.nav-elements {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: $nav-height;
  border-bottom: 1px solid $beige-20;
  transition: height $transition;

  .active-menu & {
    border-color: $dark-brown-20;
  }
}

.right-side {
  @include center-content;
  flex-direction: row;
  gap: rem(12px);

  @include media-up(md) {
    gap: rem(16px);
  }
}

.cta-button :deep(.button) {
  --button-bg-color: #{$blue};
  --button-bg-hover-color: #{$blue};
  --button-text-color: #{$pure-white};
  --button-text-hover-color: #{$pure-white};
  width: auto;
  height: rem(32px);
  padding-inline: rem(23px);
  overflow: hidden;
  font-size: rem(12px);
  font-weight: 600;
  border-radius: rem(30px);
  transition: none;

  @include media-up(md) {
    padding-inline: rem(38px);
  }

  @include media-up(lg) {
    min-width: rem(116px);
    height: rem(44px);
    padding-inline: rem(28px);
    margin-left: rem(24px);
    font-size: rem(15px);
  }

  @include media-up(xl) {
    font-size: rem(17px);
  }

  &.button--enter {
    transform: none;
  }

  &.button--leave {
    --button-bg-color: #{$orange};
    --button-bg-hover-color: #{$orange};
  }

  &::after {
    @include center-content;
    @include fill-space;
    z-index: 2;
    content: attr(data-label);
    background: $orange;
    border-radius: inherit;
  }

  .contrast-mode & {
    color: $contrast-black;
    background: $contrast-yellow;
  }

  .contrast-mode &::after {
    border-left: 1px solid $contrast-black;
  }

  .contrast-mode .active-menu &,
  .contrast-mode .active-menu &::after {
    color: $contrast-yellow;
    background: $contrast-black;
  }

  .contrast-mode .active-menu &::after {
    border-left: 1px solid $contrast-yellow;
  }
}

@keyframes primary-enter {
  100% {
    transform: translateX(100%);
  }
}

@keyframes primary-leave {
  100% {
    transform: translateX(100%);
  }
}

.cta-button :deep(.button--enter.button--primary::after) {
  animation: primary-enter $transition-slow forwards;
}

.cta-button :deep(.button--leave.button--primary::after) {
  background: $blue;
  animation: primary-leave $transition-slow forwards;
}

.background {
  position: absolute;
  top: $nav-height;
  left: 0;
  width: 100%;
  height: 0;
  background-color: $beige;
  box-shadow: 0 rem(15px) rem(20px) 0 #0000001a;
  transition: height $transition;

  .dropdown-active & {
    height: rem(170px);
  }
}
</style>
